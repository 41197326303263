section.exl-scrollable {
  position: relative;
  height: 100%;

  > div.exl-scrollable-container {
    width: 100%;
    height: 100%;
    overflow-x: visible;
    overflow-y: auto;

    box-sizing: border-box;
    position: relative;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  > div.exl-scrollable-scrollbar-wrapper {
    position: absolute;
    top: 2px;
    right: 0;
    padding: 0 1px;
    box-sizing: border-box;
    bottom: 2px;
    width: 10px;
    opacity: 0;
    overflow: visible;
    z-index: 10000;
    background-color: rgba(240, 240, 240, 0.3);
    transition: opacity 0.2s ease;
    user-select: none;

    &.disappear {
      opacity: 0;
    }

    &.appear {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 1;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 10px;
      height: 2px;
      top: -2px;
      background-color: rgba(240, 240, 240, 0.3);
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 10px;
      height: 2px;
      bottom: -2px;
      background-color: rgba(240, 240, 240, 0.3);
    }

    > div.exl-scrollable-scrollbar-thumb {
      position: absolute;
      right: 2px;
      width: 6px;
      height: 6px;
      background-color: rgba(102, 102, 102, 0.8);
      transition: height 0.4s ease-in-out;
      user-select: none;

      &:hover {
        background-color: rgba(102, 102, 102, 0.9);

        > div.exl-scrollable-scrollbar-thumb {
          background-color: rgba(102, 102, 102, 0.9);
        }
      }

      &:active {
        background-color: rgba(102, 102, 102, 1);
      }
    }
  }
}

/* section.exl-scrollable > div.exl-scrollable-container > div.exl-scrollable-content {
  내부 요소의 margin에 의한 레이아웃 오프셋이 스크롤을 망치지 않으려면 이부분이 overflow: auto; 여야한다.
  overflow: auto;
} */

@-moz-document url-prefix() {
  section.exl-scrollable > div.exl-scrollable-scrollbar-wrapper {
    display: none;
  }
}
